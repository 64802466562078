import axios from 'axios'
import { getEnableReCaptcha, getUserServiceUrl } from 'app/js/configuration/environmentConfig'

const enrolmentService = {
  createAccount: ({ email, claimNumber, postalCode, lang }, recaptchaToken) => {
    const URI = '/service/users'
    const paramData = {
      email: email,
      claim_number: claimNumber,
      postal_code: postalCode,
      lang: lang,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    if (typeof lang != "undefined") { paramData.lang = lang }
    return axios(
      {
        method: 'post',
        url: `${getUserServiceUrl()}${URI}`,
        data: paramData
      })
  },

  activateToken: (token, recaptchaToken) => {
    const URI = '/service/authn'
    const paramData = {
      token: token,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    return axios(
      {
        method: 'post',
        url: `${getUserServiceUrl()}${URI}`,
        data: paramData
      })
  },

  activateAccount: ({ stateToken, password }, recaptchaToken) => {
    const URI = '/service/authn/credentials/reset_password'
    const paramData = {
      state_token: stateToken,
      new_password: password,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    return axios(
      {
        method: 'post',
        url: `${getUserServiceUrl()}${URI}`,
        data: paramData
      })
  },

  resetPassword: ({ email }, recaptchaToken) => {
    const URI = `/service/users/${email}/recover`
    return axios(
      {
        method: 'post',
        url: `${getUserServiceUrl()}${URI}`,
        data: {
          recaptcha: {
            token: recaptchaToken,
            bypass: !getEnableReCaptcha()
          }
        },
      })
  },

  authnExpiry: () => {
    const URI = '/service/authn/expiry'
    return axios(
      {
        method: 'post',
        url: `${getUserServiceUrl()}${URI}`
      })
  }
}

export default enrolmentService