const { publicWebsiteUrl, userServiceUrl, enableReCaptcha, reCaptchaKey, signInUrl, featureFlagKey } = window.runtimeConfiguration

const getPublicWebsiteUrl = () => publicWebsiteUrl
const getUserServiceUrl = () => userServiceUrl
const getEnableReCaptcha = () => enableReCaptcha
const getRecaptchaApiKey = () => reCaptchaKey
const getSignInUrl = () => signInUrl
const getSplitConfig = () => ({
  core: {
    authorizationKey: featureFlagKey,
    key: 'CUSTOMER_ID'
  }
})

export { getPublicWebsiteUrl, getUserServiceUrl, getEnableReCaptcha, getRecaptchaApiKey, getSignInUrl, getSplitConfig }
