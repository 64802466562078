import React, { useState } from 'react'
import i18next from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import getValidationRules from './ValidationRules'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'
import TermsModal from 'app/js/pages/Terms/TermsModal'
import EnrolmentService from 'app/js/services/EnrolmentService'
import ErrorMessage from 'app/js/components/common/ErrorMessage'
import HelpMessage from 'app/js/components/common/HelpMessage'
import PageError from 'app/js/components/common/PageError'
import EnrolmentPageHelp from 'app/js/components/common/PageHelp/EnrolmentPageHelp'
import RecaptchaPageHelp from 'app/js/components/common/PageHelp/RecaptchaPageHelp'
import Loading from 'app/js/components/common/Loading'
import EmailExists from 'app/js/components/common/EmailExists'
import SignInPageLink from 'app/js/components/common/PageHelp/SignInPageLink'
import useGoogleReCaptchaIfEnabled from 'app/js/hooks/useGoogleReCaptchaIfEnabled'
import ErrorList from 'app/js/components/common/ErrorList'
import useFocusOnInvalidFormSubmission from 'app/js/hooks/useFocusOnInvalidFormSubmission'

const ERROR_CODE_USER_EXISTS = 1004

const Register = () => {
  const PAGE_NAME = 'Register'

  // State
  const [emailExists, setEmailExists] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)
  const lang = i18next.language

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm()

  // Navigation
  const navigate = useNavigate()

  // Translation
  const { t } = useTranslation()

  // ReCaptcha
  const getRecaptchaToken = useGoogleReCaptchaIfEnabled()

  // Submit handler - creates the account
  const next = async (data) => {
    setLoading(true)
    try {
      const recaptchaToken = await getRecaptchaToken()
      const accountCreationData = { ...data, lang }
      setPageError()
      EnrolmentService.createAccount(accountCreationData, recaptchaToken)
        .then(() => {
          navigate('/register/email', { state: accountCreationData })
        })
        .catch((error) => {
          const errorCode = error?.response?.data?.code
          if (errorCode === ERROR_CODE_USER_EXISTS) {
            setEmailExists(true)
          } else {
            setPageError(error?.response?.status === 400 ? 'challengeFailed' : 'generic')
          }
          setLoading(false)
        })
    } catch (error) {
      setPageError('generic')
      setLoading(false)
    }
  }

  // Validation
  const errorListRef = useFocusOnInvalidFormSubmission({ control })
  const hasError = (inputName) => typeof errors[inputName] !== 'undefined'
  const getErrorStyles = (inputName) => (hasError(inputName) ? 'error' : '')

  // Modals
  const openTerms = () => setTermsOpen(true)
  const closeTerms = () => setTermsOpen(false)
  const closeEmailExists = () => setEmailExists(false)
  const acceptTerms = () => {
    closeTerms()
    setValue('terms', true, { shouldValidate: true })
  }

  // Util function
  const enforceMaxLength = (e, maxLength) => {
    e.target.value = e.target.value.slice(0, maxLength)
  }

  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-enrolment">
          <div className="form-container">
            <h1 className="extra-margin">{t(`${PAGE_NAME}.title`)}</h1>

            {loading && <Loading />}
            {emailExists && <EmailExists onClose={closeEmailExists} />}
            {pageError && <PageError parentName={PAGE_NAME} error={pageError} />}
            <ErrorList parentName={PAGE_NAME} errors={errors} ref={errorListRef} />

            <form onSubmit={handleSubmit(next)} autoComplete="off" noValidate>
              {/* email address */}
              <div className="field">
                <label htmlFor="email" className="label">
                  {t(`${PAGE_NAME}.email.label`)}
                </label>
                <div className="control">
                  <input
                    id="email"
                    {...register('email', getValidationRules('email'))}
                    type="email"
                    placeholder={t(`${PAGE_NAME}.email.placeholder`)}
                    className={`input ${getErrorStyles('email')}`}
                    aria-required="true"
                    aria-describedby="email-error"
                    aria-invalid={hasError('email')}
                  />
                </div>
                <ErrorMessage parentName={PAGE_NAME} name="email" errors={errors} id="email-error" />
              </div>

              {/* claim number */}
              <div className="field">
                <label htmlFor="claimNumber" className="label">
                  {t(`${PAGE_NAME}.claimNumber.label`)}
                </label>
                <div className="control">
                  <input
                    id="claimNumber"
                    {...register('claimNumber', getValidationRules('claimNumber'))}
                    type="number"
                    onInput={(e) => enforceMaxLength(e, 15)}
                    placeholder={t(`${PAGE_NAME}.claimNumber.placeholder`)}
                    className={`input ${getErrorStyles('claimNumber')}`}
                    aria-required="true"
                    aria-describedby="claimNumber-help claimNumber-error"
                    aria-invalid={hasError('claimNumber')}
                  />
                </div>
                <HelpMessage parentName={PAGE_NAME} name="claimNumber" errors={errors} id="claimNumber-help" />
                <ErrorMessage parentName={PAGE_NAME} name="claimNumber" errors={errors} id="claimNumber-error" />
              </div>

              {/* postal code */}
              <div className="field">
                <label htmlFor="postalCode" className="label">
                  {t(`${PAGE_NAME}.postalCode.label`)}
                </label>
                <div className="control">
                  <input
                    id="postalCode"
                    {...register('postalCode', getValidationRules('postalCode'))}
                    type="text"
                    name="postalCode"
                    placeholder={t(`${PAGE_NAME}.postalCode.placeholder`)}
                    className={`input ${getErrorStyles('postalCode')}`}
                    maxLength={7}
                    aria-required="true"
                    aria-describedby="postalCode-error"
                    aria-invalid={hasError('postalCode')}
                  />
                </div>
                <ErrorMessage parentName={PAGE_NAME} name="postalCode" errors={errors} id="postalCode-error" />
              </div>

              {/* terms */}
              <div className="field term">
                <div className="ww-checkbox">
                  <input
                    id="terms"
                    {...register('terms', getValidationRules('terms'))}
                    type="checkbox"
                    className={`terms ${getErrorStyles('terms')}`}
                    aria-required="true"
                    aria-describedby="terms-error"
                    aria-invalid={hasError('terms')}
                  />
                  <span>
                    <label htmlFor="terms">
                      <Trans
                        i18nKey={`${PAGE_NAME}.terms.label`}
                        components={[
                          <button aria-label={t(`${PAGE_NAME}.terms.buttonLabel`)} type="button" onClick={openTerms} className="link">
                            dummyChild
                          </button>,
                        ]}
                      />
                    </label>
                  </span>
                </div>
                <ErrorMessage parentName={PAGE_NAME} name="terms" errors={errors} id="terms-error" />
              </div>

              <div className="submit">
                <button type="submit" disabled={loading} className="button is-primary is-fullwidth">
                  {t(`${PAGE_NAME}.submit.label`)}
                </button>
              </div>
            </form>

            <div className="signin">
              <SignInPageLink />
            </div>

            <EnrolmentPageHelp />
            <RecaptchaPageHelp />
          </div>
        </div>
      </main>

      {termsOpen && <TermsModal onClose={closeTerms} onAccept={acceptTerms} />}
      <Footer isEnrolment={true} className="is-enrolment" />
    </div>
  )
}

export default Register
