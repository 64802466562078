import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'
import EnrolmentPageHelp from 'app/js/components/common/PageHelp/EnrolmentPageHelp'
import RecaptchaPageHelp from 'app/js/components/common/PageHelp/RecaptchaPageHelp'
import Loading from 'app/js/components/common/Loading'
import Toast from 'app/js/components/common/Toast'
import EnrolmentService from 'app/js/services/EnrolmentService'
import useGoogleReCaptchaIfEnabled from 'app/js/hooks/useGoogleReCaptchaIfEnabled'
import AutoFocusHeading from 'app/js/components/common/AutoFocusHeading'

const Email = () => {
  const PAGE_NAME = 'Email'

  // Navigation
  const navigate = useNavigate()

  // State
  const { state } = useLocation()
  const [email, setEmail] = useState()
  const [claimNumber, setClaimNumber] = useState()
  const [postalCode, setPostalCode] = useState()
  const [lang, setLang] = useState()
  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)

  // We need this information for the resend function
  const validateInputs = () => {
    if (!state || !state.email || !state.claimNumber || !state.postalCode || !state.lang) {
      navigate('/')
    } else {
      setEmail(state.email)
      setClaimNumber(state.claimNumber)
      setPostalCode(state.postalCode)
      setLang(state.lang)
    }
  }
  useEffect(validateInputs, [state, navigate])

  // Translation
  const { t } = useTranslation()
  const getText = (key) => t(`${PAGE_NAME}.${key}`)

  // reCaptcha
  const getRecaptchaToken = useGoogleReCaptchaIfEnabled()

  // Action handlers
  const goToErrorPage = () => navigate('/register/resendfailed')
  const resend = async () => {
    setShowToast(false)
    setLoading(true)
    try {
      const recaptchaToken = await getRecaptchaToken()
      const createAccountData = { email, claimNumber, postalCode, lang }
      EnrolmentService.createAccount(createAccountData, recaptchaToken)
        .then(() => {
          setShowToast(true)
          setLoading(false)
        })
        .catch(() => {
          goToErrorPage()
        })
    } catch (error) {
      goToErrorPage()
    }
  }

  const checkIcon = <i className="fas fa-check-circle fa-sm"></i>
  const resendButton = (
    <button onClick={resend} className="link">
      dummyText
    </button>
  )

  return (
    <div>
      {loading && <Loading />}
      {showToast && <Toast className="is-success" icon={checkIcon} message={getText('resend.success')} onClose={() => setShowToast(false)} />}

      <PageTitle pageName={PAGE_NAME} />
      <Header />

      <main className="wrapper">
        <div className="container is-check-email">
          <div className="check-email-container">
            <AutoFocusHeading className="extra-margin">{getText('title')}</AutoFocusHeading>

            <div className="ww-center-content confirmation-text">
              <p>
                {getText('check_email_pre')} <span className="email-address bolded">{email}</span>.
              </p>
              <p>{getText('check_email_post')}</p>
            </div>

            <div className="resend-email-text">
              <p>{getText('resend.instructions.line1')}</p>
              <p>
                <Trans i18nKey={`${PAGE_NAME}.resend.instructions.line2`} components={[resendButton]} />
              </p>
            </div>

            <div className="check-email-help-message">
              <EnrolmentPageHelp />
              <RecaptchaPageHelp />
            </div>
          </div>
        </div>
      </main>

      <Footer isEnrolment={true} className="is-enrolment" />
    </div>
  )
}

export default Email
